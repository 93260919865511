/**
 * Created by RKL on 21/08/2015.
 */
define('productsMainRouter',[
  'require',
  'module',
  'marionette',
  'app',
  'underscore',
  'settings',
  'contentShowController',
  'productTabsLayoutController',
  'itemPagesNameMixin',
  'productTabsData'
], function (
  require,
  module,
  Marionette,
  App,
  _,
  Settings,
  ContentShowController,
  ProductTabsLayoutController,
  ItemPagesNameMixin,
  ProductTabsData
) {
  'use strict';

  var localStorageKeyProductView = 'productView';

  var ProductsRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'products': 'showProducts',
      'product/new': 'showNew',
      'product/:secId': 'selectTab',
      'product/:secId/:tab': 'selectTab',
      'product/:productSecId/pictures/:pictureSecId/:action': 'showModifyPicture'
    }
  });

  var API = {
    showProducts: function() {
      App.startSubModule('productsMain');
      if(Settings.getFromMemory(localStorageKeyProductView) === 'grid') {
        API.showProductsGrid();
      } else {
        API.showProductsTable();
      }
      App.execute('set:active:nav:item', ItemPagesNameMixin.MY_PRODUCTS);
    },

    selectTab: function(secId, tab) {
      ContentShowController.showContent().done(_.bind(function() {
        var currentTab;
        _.each(ProductTabsData, function(avTab) {
          if (!avTab.role || Settings.get('cAccountRoles')[avTab.role]) {
            if (!currentTab) {
              currentTab = avTab.name;
            }
            if (avTab.name === tab) {
              currentTab = avTab.name;
            }
          }
        });
        this['show'+currentTab+'Tab'](secId);
        App.execute('set:active:nav:item', ItemPagesNameMixin.MY_PRODUCTS);
      }, this));
    },

    showProductsTable: function() {
        require([
            'productsListController',
            'contentShowController'
        ], function(ShowController, ContentShowController) {
            ContentShowController.showContent().done(function() {
                ShowController.showProducts();
            });
        });
    },

    showNew: function () {
      ContentShowController.showContent().done(function () {
        require([
          'productOverviewTabController'
        ], function (OverviewTabController) {
          OverviewTabController.showDetails(App.regions.getRegion('main').currentView.getRegion('content'));
        });
      });
    },

    redirectToPrice: function(secId) {
      API.showpriceTab(secId);
    },

    showoverviewTab: function(secId) {
      ProductTabsLayoutController.showLayout('overview', secId).done(function (region) {
        require([
          'productOverviewTabController'
        ], function (OverviewTabController) {
          OverviewTabController.showDetails(region, secId);
        });
      });
    },

    showpriceTab: function(secId) {
      ProductTabsLayoutController.showLayout('price', secId).done(function (region, productModel) {
          require([
              'productPriceTabController'
          ], function (PriceTabController) {
              PriceTabController.showDetails(region, productModel);
          });
      });
    },

    showtranslationsTab: function(secId) {
      ProductTabsLayoutController.showLayout('translations', secId).done(function (region, productModel) {
          require([
              'productTranslationsTabController'
          ], function (TranslationsTabController) {
            TranslationsTabController.showDetails(region, productModel);
          });
      });
    },

    showshareTab: function(secId) {
      ProductTabsLayoutController.showLayout('share', secId).done(function (region, productModel) {
          require([
              'productShareTabController'
          ], function (ShareTabController) {
            ShareTabController.showDetails(region, productModel);
          });
      });
    },

    showfiltersTab: function(secId) {
      ProductTabsLayoutController.showLayout('filters', secId).done(function (region, productModel) {
          require([
              'productFilterTabController'
          ], function (FilterTabController) {
            FilterTabController.showDetails(region, productModel);
          });
      });
    },

    showProductsGrid: function () {
      require([
        'contentShowController',
        'productGridController'
      ], function (ContentShowController, GridController) {
        ContentShowController.showContent().done(function () {
          GridController.showProductsGrid(App.regions.getRegion('main').currentView.getRegion('content'));
        });
      });
    },

    showpicturesTab: function(secId) {
      ProductTabsLayoutController.showLayout('pictures', secId).done(function(region, productModel) {
        require([
          'productPicturesTabController'
        ], function(PicturesTabController) {
          PicturesTabController.showPicturesTab(region, productModel);
        });
      });
    },

    showModifyPicture: function(productSecId, pictureSecId, action) {
      ContentShowController.showContent().done(function() {
        ProductTabsLayoutController.showLayout('pictures', productSecId).done(function(region) {
          require([
              'productOverviewTabController'
            ], function(OverviewTabController) {
            OverviewTabController.showModifyPictureTab(region, productSecId, pictureSecId, action);
          });
        });
      });
    }
  };

  App.on('products:show', function () {
    App.navigate('products');
    API.showProducts();
  });

  App.on('products:show:grid', function() {
    Settings.setToMemory(localStorageKeyProductView, 'grid');
    App.navigate('products');
    API.showProductsGrid();
  });

  App.on('product:show', function (productSecId) {
    var productLink = productSecId ? '/' + productSecId : '';
    App.navigate('product' + productLink, true);
  });

  App.on('product:new', function () {
    App.navigate('product/new', true);
  });

  App.on('before:start', function () {
    new ProductsRouter({
      controller: API
    });
  });

  module.exports = ProductsRouter;
});
