
define('template!dynamicTextLocalizedTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-group" data-field-name="' +
((__t = ( lang )) == null ? '' : __t) +
'">\n    <label class="col-xs-3 control-label">' +
((__t = ( lang )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-8">\n        <input type="text" class="form-control floating-label js-info-input" \n            data-field-name="' +
((__t = ( lang )) == null ? '' : __t) +
'" \n            placeholder="' +
((__t = ( lang )) == null ? '' : __t) +
'" value="' +
((__t = ( value )) == null ? '' : __t) +
'">\n    </div>\n    <div class="col-xs-1">\n        <i class="mdi mdi-action-settings-backup-restore btn-revert-dt clickable" data-field-name="' +
((__t = ( lang )) == null ? '' : __t) +
'" style="' +
((__t = ( revertable?'':'display: none;' )) == null ? '' : __t) +
'"></i>\n    </div>\n</div>';

}
return __p
};});

