define( 'entities/product',[
  'app',
  'jquery',
  'settings',
  'backbone',
  'underscore'
], function(
  App,
  $,
  Settings,
  Backbone,
  _
) {
  'use strict';

  var Product = Backbone.Model.extend({
    url: function() {
      return Settings.url('compuzz', 'products.json/product/' + this.get('secId'));
    },

    fetchByCode: function(code) {
      var p = Settings.get(code);
      if (p) {
          var defer = $.Deferred();
          this.set(p);
          defer.resolve(p);
          return defer.promise();
      } else {
        this.url = Settings.url('compuzz', 'products.json/' + code);
        var prom = this.fetch();
        prom.done(_.bind(function(pr) {
            this.set(pr);
            Settings.set(code, pr);
        }, this));
        return prom;
      }
    },

    fullFetch: function(code) {
        this.url = Settings.url('compuzz', 'products.json/' + code, {
          loadPrintAreas: true,
          loadOptionCategories: true,
          loadProductPictures: true
        });
        return this.fetch();
    },

    idAttribute: 'secId'
  });

  var ProductCollection = Backbone.Collection.extend({
    fetch: function(params) {
      this.url = Settings.url('compuzz', 'products.json/advancedSearch', params);
      return Backbone.Collection.prototype.fetch.call(this);
    },

    getCount: function(params) {
      var that = this;

      that.trigger('count:request');
      return $.ajax({
        url: Settings.url('compuzz', 'products.json/advancedSearch/rowCount', params),
        success: function(count) {
          that.trigger('count:sync', count);
        }
      });
    },

    model: Product
  });

  var API = {
    addProductToCatalogue: function(productSecId, catalogueSecId) {
      var defer = $.Deferred(),
          url;

      url = Settings.url('compuzz', 'catalogues.json/secId/' + catalogueSecId + '/addItem/product/' + productSecId);
      $.ajax({
        type: 'PUT',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    addMultipleProductsToCatalogue: function(productsSecIds, catalogueSecId) {
      var defer = $.Deferred(),
          url;

      url = Settings.url('compuzz', 'catalogues.json/secId/' + catalogueSecId + '/addItem/product');
      $.ajax({
        type: 'PUT',
        url: url,
        data: JSON.stringify(productsSecIds),
        contentType: 'application/json',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getPriceForConfiguration: function(secId, configuration, buyingPrice) {
      var defer = $.Deferred(),
          url;

      url = Settings.url('compuzz', 'products.json/'+secId+'/configuration/price', { buyingPrice: buyingPrice });
      $.ajax({
          type: 'POST',
          url: url,
          data: JSON.stringify(configuration),
          contentType: 'application/json',
          success: function (data) {
              defer.resolve(data);
          }
      });
      return defer.promise();
    }
  };

  App.reqres.setHandler('products:row-count-url', function () {
    return 'products.json/advancedSearch/rowCount';
  });

  App.reqres.setHandler('products:get-collection-url-by-catalogue', function (catalogueSecId) {
    return Settings.url('compuzz', 'products.json/advancedSearch', API.getCatalogueQueryParam(catalogueSecId));
  });

  App.reqres.setHandler('products:row-count-url-by-catalogue', function (catalogueSecId) {
    return 'products.json/advancedSearch/rowCount?onlyFromCatalogue=true&catalogSecId=' + catalogueSecId;
  });

  App.reqres.setHandler('products:get-collection-url', function () {
    return Settings.url('compuzz', 'products.json/advancedSearch?loadproductcodevariation=false&loadOptionCategories=false');
  });

  App.reqres.setHandler('products:model', function (productJSON) {
    return new Product(productJSON);
  });

  App.reqres.setHandler('products:collection', function(productsJSON) {
    return new ProductCollection(productsJSON);
  });
  App.reqres.setHandler('products:add-product-to-catalogue', function (productSecId, catalogueSecId) {
    return API.addProductToCatalogue(productSecId, catalogueSecId);
  });
  App.reqres.setHandler('products:add-multiple-products-to-catalogue', function (productsSecIds, catalogueSecId) {
    return API.addMultipleProductsToCatalogue(productsSecIds, catalogueSecId);
  });
  App.reqres.setHandler('products:get-price-for-configuration', function (secId, configuration, buyingPrice) {
      return API.getPriceForConfiguration(secId, configuration, buyingPrice);
  });
  App.reqres.setHandler('products:get-product', function (index, filters) {
    var defer = $.Deferred(),
        url;
    if (!filters) {
      filters = {}; 
    }
    filters.pageSize = 1;
    filters.first = index;

    url = Settings.url('compuzz', 'products.json/advancedSearch', filters);
    $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
            defer.resolve(data[0]);
        }
    });
    return defer.promise();
  });
});
