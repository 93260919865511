
define('template!offersKanbanViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="well offers-container kanban-board kanban-board-offer">\n    <h2>' +
((__t = ( _.i18n('menu.offerManagement') )) == null ? '' : __t) +
'</h2>\n    <div class="sub-caccounts-table">\n      <div class="form-group add-new-block" style="display: none;">\n        <a target="_self" class="btn new-offer-button add-btn-text">\n          <i class="mdi-content-add"></i>\n          <span>' +
((__t = ( _.i18n('offers.new') )) == null ? '' : __t) +
'</span>\n        </a>\n        <a class="download offers-export btn-link" href="">\n          <i class="mdi-file-file-download alligned-middle"></i>\n          <span class="action-label alligned-middle">' +
((__t = ( _.i18n("common.download") )) == null ? '' : __t) +
'</span>\n        </a>\n        <a class="download offers-pdf-download" href="" download></a>\n        <div class="js-action-buttons-region offers-action-button f-r"></div>\n      </div>\n    </div>\n    <div id="kanbanoffer-filters-region"></div>\n    <div id="kanbanoffer-board-region"></div>\n  </div>\n</div>';

}
return __p
};});

