
define('template!productGridLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="js-view-switcher-region f-r m-r-15 m-t-15 p-l-5 p-r-5"></div>\n<div class="well jq-grid-table-page">\n  <h2>' +
((__t = ( _.i18n('menu.products') )) == null ? '' : __t) +
'</h2>\n  <div class="sub-caccounts-table">\n    <div class="form-group add-new-block">\n      <a href="#product/new" class="btn new-cAccount-button add-btn-text">\n        <i class="mdi-content-add"></i>\n        <span>' +
((__t = ( _.i18n('products.new') )) == null ? '' : __t) +
'</span>\n      </a>\n    </div>\n  </div>\n  <div class="js-filters-region"></div>\n  <div class="grid-list row lt-deviders-bg m-l-0 m-r-0">\n    <div class="js-list-region"></div>\n    <div class="js-loader grid-list-loader">' +
((__t = ( _.i18n('common.loading') )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="js-pagination-region row m-0"></div>\n</div>';

}
return __p
};});

