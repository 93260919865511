
define('template!orderTabsHeaderTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="vertical-divider col-lg-1"></div>\n<div id="order-header-status" class="col-left name-holder">\n    <p>' +
((__t = ( status )) == null ? '' : __t) +
'</p>\n    <p>' +
((__t = ( _.i18n("orderList.agentId") )) == null ? '' : __t) +
': ' +
((__t = ( agentId )) == null ? '' : __t) +
'</p>\n</div>\n<div class="warning-price" style="float:left; display: none;">\n    <i class="mdi-alert-warning clickable" style="font-size:38px;" title="' +
((__t = ( _.i18n("orderList.priceDiff") )) == null ? '' : __t) +
'"></i>\n</div>\n<span class="cards-checkbox-container" style="font-size: 30px;">\n    ';
 if (payedByCustomer) { ;
__p += '\n        <i class="mdi-editor-attach-money" title="' +
((__t = ( _.i18n('orderList.payedByCustomer') )) == null ? '' : __t) +
'" aria-hidden="true"></i>\n    ';
 } ;
__p += '\n    ';
 if (payedToManufacturer) { ;
__p += '\n        <i class="fa fa-check-circle" aria-hidden="true"></i>\n    ';
 } ;
__p += '\n    ';
 if (toInvoice) { ;
__p += '\n        ';
 if (invoiceFileUidLink) { ;
__p += '\n        <a href="' +
((__t = ( invoiceFileUidLink )) == null ? '' : __t) +
'" target="_blank">\n                    <i class="mdi-file-folder" title="' +
((__t = ( _.i18n('orderList.invoice') )) == null ? '' : __t) +
'" aria-hidden="true"></i>\n                    </a>\n        ';
 } else { ;
__p += '\n        <i class="mdi-file-folder-open" title="' +
((__t = ( _.i18n('orderList.invoice') )) == null ? '' : __t) +
'" aria-hidden="true"></i>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n    ';
 if (nbrPackSent > 0) { ;
__p += '\n        ';
 if (nbrPackSent === nbrPackDelivered) { ;
__p += '\n            <i class="mdi-maps-local-shipping icon-tracking" title="' +
((__t = ( _.i18n('orderList.delivered') )) == null ? '' : __t) +
'" style="color: green;" aria-hidden="true"></i>\n        ';
 } else { ;
__p += '\n            <i class="mdi-maps-local-shipping icon-tracking" title="' +
((__t = ( _.i18n('orderList.onTheWay') )) == null ? '' : __t) +
'" style="color: orange;" aria-hidden="true"></i>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n        </span>\n<div class="js-action-buttons-region orders-action-button f-r"></div>\n<input type="file"\n       id="order-proofsheet-input"\n       class="hidden js-upload-proofsheet " data-icon="true"\n       multiple="single"\n       accept="application/pdf"/>\n<input type="file"\n       id="order-export-input"\n       class="hidden js-upload-export " data-icon="true"\n       multiple="single"\n       accept="application/ai"/>\n<span class="orders-back-to-list back-to-list js-back-to-list"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n';

}
return __p
};});

