
define('template!orderCompositionTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content order-personalization">\n    ';
 if (proofsheetFileUid) { ;
__p += '\n        ' +
((__t = ( _.i18n("order.customProofsheet") )) == null ? '' : __t) +
' : <a href="' +
((__t = ( customProofSheetUrl )) == null ? '' : __t) +
'">' +
((__t = ( purchaseOrderId )) == null ? '' : __t) +
'.pdf</a>\n        <i class=\'mdi-action-delete js-delete-custom-proofsheet clickable\'></i><br />\n    ';
 } ;
__p += '\n    ';
 if (exportFileUid) { ;
__p += '\n        ' +
((__t = ( _.i18n("order.customExport") )) == null ? '' : __t) +
' : <a href="' +
((__t = ( customExportUrl )) == null ? '' : __t) +
'">' +
((__t = ( purchaseOrderId )) == null ? '' : __t) +
'_artwork</a>\n        <i class=\'mdi-action-delete js-delete-custom-export clickable\'></i><br />\n    ';
 } ;
__p += '\n    <label>' +
((__t = ( _.i18n("order.dtpComment") )) == null ? '' : __t) +
'</label>\n    <p class="order-product-comment">' +
((__t = ( comment.replace(/[\n]/g, '<br />') )) == null ? '' : __t) +
'</p>\n  <div class="js-print-area-container-region">\n  </div>\n</div>';

}
return __p
};});

