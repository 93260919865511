
define('template!trackingCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n        <div class="form-group" data-field-name="carrier">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('tracking.carrier') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <select role="select" name="carrier" id="gs_personalized"\n                        class="ui-widget-content ui-corner-all">\n                    <option ';
 if(carrier === 'bpost'){ ;
__p += 'selected';
};
__p += '\n                            role="option" value="bpost">BPOST</option>\n                    <option ';
 if(carrier === 'dhl'){ ;
__p += 'selected';
};
__p += '\n                            role="option" value="dhl">DHL</option>\n                    <option ';
 if(carrier === 'dpd'){ ;
__p += 'selected';
};
__p += '\n                            role="option" value="dpd">DPD</option>\n                    <option ';
 if(carrier === 'tnt'){ ;
__p += 'selected';
};
__p += '\n                            role="option" value="tnt">TNT</option>\n                    <option ';
 if(carrier === 'ups'){ ;
__p += 'selected';
};
__p += '\n                            role="option" value="ups">UPS</option>\n                </select>\n            </div>\n        </div>\n      <div class="form-group" data-field-name="trackingNumber">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('tracking.trackingNumber') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="trackingNumber" placeholder="' +
((__t = ( _.i18n('tracking.trackingNumber') )) == null ? '' : __t) +
'" value="' +
((__t = ( trackingNumber )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n        <div class="form-group" data-field-name="trackingStatus">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('tracking.trackingStatus') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="text" class="form-control floating-label js-info-input" data-field-name="trackingStatus" placeholder="' +
((__t = ( _.i18n('tracking.trackingStatus') )) == null ? '' : __t) +
'" value="' +
((__t = ( trackingStatus )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

