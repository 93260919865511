define('menuItems',[
    'underscore',
    'settings',
    'itemPagesNameMixin',
    'rolesMixin'
], function (_,
    Settings,
    ItemPagesNameMixin,
    RolesMixin) {
        'use strict';

        var SidebarMenuItems = { build: function() {
            return [{
                //ACCOUNT
                title: _.i18n('menu.account'),
                itemPageName: ItemPagesNameMixin.ACCOUNT,
                link: function () {
                    return '';
                },
                role: RolesMixin.DASHBOARD_ACCOUNT,
                icon: 'fa fa-user fa-lg',
                subMenu: [
                    {
                        title: _.i18n('menu.myAccount'),
                        itemPageName: ItemPagesNameMixin.MY_ACCOUNT,
                        link: function () {
                            return 'caccount/' + (Settings.get('currentCAccount') ? Settings.get('currentCAccount').secId : '');
                        },
                        role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT,
                        icon: 'fa fa-home fa-lg',
                        subMenu: []
                    },
                    {
                        title: _.i18n('menu.account.graphicalId'),
                        itemPageName: ItemPagesNameMixin.GRAPHICAL_ID,
                        link: function () {
                            return 'graphicalIdentity/' + (Settings.get('currentCAccount') ? Settings.get('currentCAccount').secId : '');
                        },
                        role: RolesMixin.DASHBOARD_ACCOUNT_GRAPHICALID,
                        icon: 'mdi-communication-quick-contacts-mail',
                        subMenu: []
                    },
                    {
                        title: _.i18n('menu.account.mails'),
                        itemPageName: ItemPagesNameMixin.MAILS,
                        link: function () {
                            return 'mails';
                        },
                        role: RolesMixin.DASHBOARD_ACCOUNT_MAILS,
                        icon: 'mdi-communication-quick-contacts-mail',
                        subMenu: []
                    }
                ]
            },
                //PRODUCTS
                {
                    title: _.i18n('menu.products'),
                    itemPageName: ItemPagesNameMixin.PRODUCTS,
                    link: function () {
                        return '';
                    },
                    role: RolesMixin.DASHBOARD_PRODUCTS,
                    icon: 'fa fa-cubes fa-lg',
                    subMenu: [
                        {
                            title: _.i18n('menu.products.myProducts'),
                            itemPageName: ItemPagesNameMixin.MY_PRODUCTS,
                            link: function () {
                                return 'products';
                            },
                            role: RolesMixin.DASHBOARD_PRODUCTS_PRODUCTS,
                            icon: 'fa fa-home fa-lg',
                            subMenu: []
                        },
                        {
                            title: _.i18n('menu.products.prices'),
                            itemPageName: ItemPagesNameMixin.PRODUCTS_PRICELISTS,
                            link: function () {
                                return 'prices';
                            },
                            role: RolesMixin.DASHBOARD_PRODUCTS_PRICELISTS,
                            icon: 'fa fa-tags fa-lg',
                            subMenu: []
                        },
                        {
                            title: _.i18n('menu.products.catalogues'),
                            itemPageName: ItemPagesNameMixin.PRODUCTS_CATALOGUES,
                            link: function () {
                                return 'catalogues/mine';
                            },
                            role: RolesMixin.DASHBOARD_PRODUCTS_CATALOGUES,
                            icon: 'mdi-image-photo-album',
                            subMenu: []
                        }]
                },
                //CUSTOMERS
                {
                    title: _.i18n('menu.clients'),
                    itemPageName: ItemPagesNameMixin.CLIENTS,
                    link: function () {
                        return '';
                    },
                    role: RolesMixin.DASHBOARD_CUSTOMERS,
                    icon: 'fa fa-users fa-lg',
                    subMenu: [
                        {
                            title: _.i18n('menu.clients.myClients'),
                            itemPageName: ItemPagesNameMixin.MY_CLIENTS,
                            link: function () {
                                return 'customers';
                            },
                            role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS,
                            icon: 'fa fa-home fa-lg',
                            subMenu: []
                        }]
                },
                //ORDERS
                {
                    title: Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2] ?
                        _.i18n('menu.orderManagement') : _.i18n('menu.ordersManagement'),
                    itemPageName: ItemPagesNameMixin.ORDERS,
                    link: function () {
                        return '';
                    },  
                    role: RolesMixin.DASHBOARD_ORDERS,
                    icon: 'fa fa-list-alt fa-lg',
                    subMenu: [
                        {
                            title: _.i18n('menu.orderManagement.list'),
                            itemPageName: ItemPagesNameMixin.ORDERS_LIST,
                            link: function () {
                                return 'orders';
                            },
                            role: RolesMixin.DASHBOARD_ORDERS,
                            icon: 'fa fa-list-alt fa-lg',
                            subMenu: []
                        }, {
                            title: _.i18n('menu.orderManagement.board'),
                            itemPageName: ItemPagesNameMixin.ORDERS_BOARD,
                            link: function () {
                                return 'orders_kanban';
                            },
                            role: RolesMixin.DASHBOARD_ORDERS,
                            icon: 'fa fa-list-alt fa-lg',
                            subMenu: []
                        }]
                },
                //OFFERS
                {
                    title: _.i18n('menu.offerManagement'),
                    itemPageName: ItemPagesNameMixin.OFFERS,
                    link: function () {
                        return '';
                    },
                    role: RolesMixin.DASHBOARD_OFFERS,
                    icon: 'fa fa-list-alt fa-lg',
                    subMenu: [
                        {
                            title: _.i18n('menu.offerManagement.list'),
                            itemPageName: ItemPagesNameMixin.OFFERS_LIST,
                            link: function () {
                                return 'offers';
                            },
                            role: RolesMixin.DASHBOARD_OFFERS,
                            icon: 'fa fa-list-alt fa-lg',
                            subMenu: []
                        }, {
                            title: _.i18n('menu.orderManagement.board'),
                            itemPageName: ItemPagesNameMixin.OFFERS_BOARD,
                            link: function () {
                                return 'offers_kanban';
                            },
                            role: RolesMixin.DASHBOARD_OFFERS,
                            icon: 'fa fa-list-alt fa-lg',
                            subMenu: []
                        }]
                },
                //SUPPLIERS
                {
                    title: _.i18n('menu.suppliers'),
                    itemPageName: ItemPagesNameMixin.SUPPLIERS,
                    link: function () {
                        return '';
                    },
                    role: RolesMixin.DASHBOARD_SUPPLIERS,
                    icon: 'fa fa-truck fa-lg',
                    subMenu: [
                        {
                            title: _.i18n('menu.suppliers.catalogues'),
                            itemPageName: ItemPagesNameMixin.SUPPLIERS_CATALOGUES,
                            link: function () {
                                return 'catalogues/shared';
                            },
                            role: RolesMixin.DASHBOARD_SUPPLIERS_CATALOGUES,
                            icon: 'mdi-image-photo-album',
                            subMenu: []
                        }]
                },
                //GRAPHICAL REQUEST
                {
                    title: _.i18n('menu.graphicalRequests'),
                    itemPageName: ItemPagesNameMixin.GRAPHICALREQUESTS,
                    link: function () {
                        return '';
                    },
                    role: RolesMixin.DASHBOARD_GRAPHICALREQUESTS,
                    icon: 'mdi-action-extension',
                    subMenu: [
                        {
                            title: _.i18n('menu.graphicalRequests.kanban'),
                            itemPageName: ItemPagesNameMixin.GRAPHICAL_SERVICE_KANBAN,
                            link: function () {
                                return 'graphical_service_kanban';
                            },
                            role: RolesMixin.VECTORIZATOR,
                            icon: 'mdi-image-grain',
                            subMenu: []
                        },
                        {
                            title: _.i18n('menu.graphicalRequests.allInOne'),
                            itemPageName: ItemPagesNameMixin.GRAPHICALREQUESTS_ALL_IN_ONE,
                            link: function () {
                                return 'graphical_service_all_in_one';
                            },
                            role: RolesMixin.DASHBOARD_GRAPHICALREQUESTS_ALL_IN_ONE,
                            icon: 'mdi-image-grain',
                            subMenu: []
                        },
                        {
                            title: _.i18n('menu.vectorizationRequest'),
                            itemPageName: ItemPagesNameMixin.GRAPHICALREQUESTS_VECTORIZATION,
                            link: function () {
                                return 'vectorization';
                            },
                            role: RolesMixin.DASHBOARD_GRAPHICALREQUESTS_VECTORIZATION,
                            icon: 'mdi-image-grain',
                            subMenu: []
                        },
                        {
                            title: _.i18n('menu.fileconversion'),
                            itemPageName: ItemPagesNameMixin.GRAPHICALREQUESTS_FILECONVERSION,
                            link: function () {
                                return 'file_conversion';
                            },
                            role: RolesMixin.DASHBOARD_GRAPHICALREQUESTS_FILECONVERSION,
                            icon: 'mdi-av-repeat',
                            subMenu: [],
                            isNew: false
                        },
                        {
                            title: _.i18n('menu.backRemove'),
                            itemPageName: ItemPagesNameMixin.GRAPHICALREQUESTS_REMOVEBACKGROUND,
                            link: function () {
                                return 'remove_background';
                            },
                            role: RolesMixin.DASHBOARD_GRAPHICALREQUESTS_REMOVEBACKGROUND,
                            icon: 'mdi-content-content-cut',
                            subMenu: [],
                            isNew: false
                        }
                    ]
                },
                //ADMIN
                {
                    title: _.i18n('menu.admin'),
                    itemPageName: ItemPagesNameMixin.ADMIN,
                    link: function () {
                        return 'admin';
                    },
                    role: RolesMixin.DASHBOARD_ADMIN,
                    icon: 'fa fa-cog fa-lg',
                    subMenu: [
                        {
                            title: _.i18n('menu.subCaccount'),
                            itemPageName: ItemPagesNameMixin.ADMIN_SUBACCOUNTS,
                            link: function () {
                                return 'subcaccounts';
                            },
                            role: 'DASHBOARD_SUBACCOUNTCREATOR',
                            icon: 'fa fa-briefcase fa-lg',
                            subMenu: []
                        },
                        {
                            title: _.i18n('menu.translations'),
                            itemPageName: ItemPagesNameMixin.ADMIN_TRANSLATIONS,
                            link: function () {
                                return 'translations';
                            },
                            role: RolesMixin.DASHBOARD_ADMIN_TRANSLATIONS,
                            icon: 'fa fa-language fa-lg',
                            subMenu: []
                        },
                        {
                            title: _.i18n('menu.timetracker'),
                            itemPageName: ItemPagesNameMixin.ADMIN_TIMETRACKING,
                            link: function () {
                                return 'timetracker';
                            },
                            role: RolesMixin.DASHBOARD_TIMETRACKER,
                            icon: 'fa fa-calendar fa-lg',
                            subMenu: []
                        },
                        {
                            title: _.i18n('menu.mywebsite'),
                            itemPageName: ItemPagesNameMixin.ADMIN_MY_WEBSITE,
                            link: function () {
                                return 'my_website';
                            },
                            role: RolesMixin.DASHBOARD_MYWEBSITE,
                            icon: 'fa fa-calendar fa-lg',
                            subMenu: []
                        }
                    ]
                },
                //PHONE
                {

                    title: _.i18n('menu.phone'),
                    itemPageName: ItemPagesNameMixin.DASHBOARD_PHONE,
                    link: function () {
                        return '';
                    },
                    role: RolesMixin.DASHBOARD_PHONE,
                    icon: 'fa fa-phone fa-lg',
                    subMenu: [
                        {
                            title: _.i18n('menu.phone.numbers'),
                            itemPageName: ItemPagesNameMixin.PHONE_NUMBERS,
                            link: function () {
                                return 'phone/numbers';
                            },
                            role: RolesMixin.DASHBOARD_PHONE,
                            icon: 'fa fa-phone fa-lg',
                            subMenu: []
                        },
                        {
                            title: _.i18n('menu.calls'),
                            itemPageName: ItemPagesNameMixin.PHONE_CALLS,
                            link: function () {
                                return 'calls';
                            },
                            role: RolesMixin.DASHBOARD_PHONE,
                            icon: 'fa fa-phone fa-lg',
                            subMenu: []
                        }
                    ]
                },
                //SUPER ADMIN
                {
                    title: _.i18n('menu.superAdmin'),
                    itemPageName: ItemPagesNameMixin.SUPERADMIN,
                    link: function () {
                        return 'superAdmin';
                    },
                    role: RolesMixin.DASHBOARD_SUPERADMIN,
                    icon: 'fa fa-cogs fa-lg',
                    subMenu: [
                        {
                            title: _.i18n('superAdmin.overview.tabTitle'),
                            itemPageName: ItemPagesNameMixin.SUPERADMIN_OVERVIEW,
                            link: function () {
                                return 'superAdmin/overview';
                            },
                            role: RolesMixin.DASHBOARD_SUPERADMIN,
                            icon: 'fa fa-globe fa-lg',
                            subMenu: []
                        },
                        {
                            title: _.i18n('menu.superadmin.caccounts'),
                            itemPageName: ItemPagesNameMixin.SUPERADMIN_CACCOUNTS,
                            link: function () {
                                return 'superAdmin/caccounts';
                            },
                            role: RolesMixin.DASHBOARD_SUPERADMIN,
                            icon: 'fa fa-male',
                            subMenu: []
                        }
                    ]
                }, {
                title: _.i18n('menu.invitations'),
                itemPageName: ItemPagesNameMixin.INVITATIONS,
                link: function () {
                    return 'invitations';
                },
                role: RolesMixin.DASHBOARD_INVITATIONS,
                icon: 'fa fa-paper-plane fa-lg'
            }];
        }
    };
        return SidebarMenuItems;
    });
