
define('template!orderCompositionItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="order-composition-tab">\n\t<div class="well shadow-1 container-fluid">\n\t\t<h3 class="title">\n\t\t\t' +
((__t = ( printAreaName )) == null ? '' : __t) +
'\n\t\t</h3>\n\t\t<div class="col-xs-3 print-area-image-container">\n\t\t\t<img class="img-responsive composition-img" src="' +
((__t = ( prinAreaImgUrl )) == null ? '' : __t) +
'"/>\n            <div class="form-group row">\n                <div class="col-xs-2">\n                    <input type="checkbox" style="width: 20px; height: 40px;" class="useBackgroundColor" ' +
((__t = ( useBackgroundColor?'checked':'')) == null ? '' : __t) +
'>\n                </div>\n                <label class="col-xs-10 control-label p-t-15 p-l-0" style="text-align: left;">' +
((__t = ( _.i18n("composition.useBackgroundColor") )) == null ? '' : __t) +
'</label>\n            </div>\n\t\t</div>\n\t\t<div class="col-md-offset-1 col-xs-6">\n            <h4 class="itName">' +
((__t = ( itName )) == null ? '' : __t) +
'</h4>\n            ';
 if (usedColors.length !== 0) { ;
__p += '\n                <ul class="list-unstyled used-color-list">\n                    ';
 _.each(usedColors, function(item) { ;
__p += '\n                        <li>\n                            <div class="used-color-box" style="background-color:' +
((__t = ( item.hexaValue )) == null ? '' : __t) +
'"></div>\n                            <span>' +
((__t = ( item.name )) == null ? '' : __t) +
'</span>\n                        </li>\n                    ';
 }); ;
__p += '\n                </ul>\n            ';
 } ;
__p += '\n\n            ';
 if (customData) { ;
__p += '\n                <table style="width:100%">\n                    ';
 for(var propertyName in customData) { ;
__p += '\n                        <tr>\n                            <td>' +
((__t = ( propertyName )) == null ? '' : __t) +
'</td>\n                            ';
 var item = customData[propertyName]; ;
__p += '\n                            ';
 if (Array.isArray(item)) { ;
__p += '\n                                <td>\n                                    ';
 for(var i = 0 ; i < item.length ; i++) { ;
__p += '\n                                        <table style="width:100%">\n                                            ';
 var first = true; ;
__p += '\n                                            ';
 for(var propertyName1 in item[i]) { ;
__p += '\n                                                <tr>\n                                                    <td>' +
((__t = ( first?(i+1):'' )) == null ? '' : __t) +
'</td>\n                                                    <td>' +
((__t = ( propertyName1 )) == null ? '' : __t) +
'</td>\n                                                    <td>' +
((__t = ( item[i][propertyName1] )) == null ? '' : __t) +
'</td>\n                                                    <!--<td><i class="mdi mdi-editor-mode-edit btn-edit-property clickable"\n                                                           data-list-name="' +
((__t = ( propertyName )) == null ? '' : __t) +
'" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'" data-property="propertyName1"></i></td>-->\n                                                <tr>\n                                            ';
 first = false; ;
__p += '\n                                            ';
 } ;
__p += '\n                                        </table>\n                                    ';
 } ;
__p += '\n                                </td>\n                            ';
 } else { ;
__p += '\n                                <td>' +
((__t = ( customData[propertyName] )) == null ? '' : __t) +
'</td>\n                            ';
 } ;
__p += '\n                        </tr>\n                    ';
 } ;
__p += '\n                </table>\n            ';
 } ;
__p += '\n\t\t</div>\n        <div class="col-xs-2">\n            <a href="' +
((__t = ( compuzzProProtocol )) == null ? '' : __t) +
'://' +
((__t = ( tokenId )) == null ? '' : __t) +
'" class="btn-connect-compuzzpro">Connect compuzz pro</a></br>\n            <span class="btn-edit-compuzzpro clickable">Edit compuzz pro</span>\n        </div>\n\t</div>\n</div>';

}
return __p
};});

