
define('template!ordersKanbanViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content">\n  <div class="well orders-container kanban-board kanban-board-order">\n    <h2>' +
((__t = ( _.i18n('menu.orderManagement') )) == null ? '' : __t) +
'</h2>\n    <div class="sub-caccounts-table">\n      ';
 if(newOrderLogotoolUrl){ ;
__p += '\n      <div class="form-group add-new-block">\n        <a href="' +
((__t = ( newOrderLogotoolUrl )) == null ? '' : __t) +
'" target="_self" class="btn new-cAccount-button add-btn-text">\n          <i class="mdi-content-add"></i>\n          <span>' +
((__t = ( _.i18n('orders.new') )) == null ? '' : __t) +
'</span>\n        </a>\n        <a class="download orders-export btn-link" href="">\n          <i class="mdi-file-file-download alligned-middle"></i>\n          <span class="action-label alligned-middle">' +
((__t = ( _.i18n("common.download") )) == null ? '' : __t) +
'</span>\n        </a>\n        <a class="download orders-pdf-download" href="" download></a>\n        <div class="js-action-buttons-region orders-action-button f-r"></div>\n      </div>\n      ';
 } ;
__p += '\n    </div>\n    <div id="kanbanorder-filters-region"></div>\n    <div id="kanbanorder-board-region"></div>\n  </div>\n</div>';

}
return __p
};});

