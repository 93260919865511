
define('template!shareOptionCategoryTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12 p-b-10">\n        <div class="col-xs-6">\n            <div class="col-xs-1 m-t--7 m-b--5">\n                <input class="cbx-optioncategory" type="checkbox" style="width: 20px; height: 40px;" ' +
((__t = ( checked?'checked':'')) == null ? '' : __t) +
'>\n              </div>\n            <div class="col-xs-2">' +
((__t = ( parentOptionCategory.code )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-4">' +
((__t = ( checked?optionCategory.name:parentOptionCategory.name )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="share-options col-xs-6">\n        </div>\n    </div>\n    <hr class="col-xs-12 m-t-0"/>\n</div>';

}
return __p
};});

