
define('template!productPicturesTabPictureItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="image-texture-and-background">\n  <img class="js-image-holder" src="' +
((__t = ( pictureImageUrl )) == null ? '' : __t) +
'"/>\n</div>\n<div class="picture-editor">\n  <i class="mdi-content-content-copy icon js-copy"></i>\n  <i class="mdi-editor-mode-edit icon js-edit"></i>\n  <i class="mdi-image-color-lens icon js-print"></i>\n  <i class="mdi-action-delete icon js-delete"></i>\n</div>';

}
return __p
};});

