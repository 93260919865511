
define('template!sharePrintZoneTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-1 m-t--7 m-b--5">\n        <input type="checkbox" class="cbx-printzone" style="width: 20px; height: 40px;" ' +
((__t = ( checked?'checked':'')) == null ? '' : __t) +
'>\n      </div>\n    <div class="col-xs-11">' +
((__t = ( checked?printZone.name:parentPrintZone.name )) == null ? '' : __t) +
'</div>\n</div>\n';

}
return __p
};});

