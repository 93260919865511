
define('template!editEndUserAttachmentsPluginTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content full-height" style="height: 800px;">\n  <div class="well full-height">\n    <h3 class="iframeLabel">' +
((__t = ( title )) == null ? '' : __t) +
'</h3>\n    <div class="iframe-full-screen-container">\n      <div class="alert alert-dismissible alert-info">\n        <button type="button" class="close" data-dismiss="alert">×</button>\n        <strong>' +
((__t = ( _.i18n('common.click') )) == null ? '' : __t) +
' </strong>\n        <a href="javascript:void(0)" class="alert-link">' +
((__t = ( _.i18n('common.here') )) == null ? '' : __t) +
'</a><span id="alert-info-text">, ' +
((__t = ( _.i18n('common.tooltip.fullscreen') )) == null ? '' : __t) +
'.</span>\n      </div>\n      <iframe class="iframeCompuzz"  id="iframeCompuzz" src="' +
((__t = ( url )) == null ? '' : __t) +
'" frameborder="0" seamless>\n      </iframe>\n    </div>\n  </div>\n</div>';

}
return __p
};});

