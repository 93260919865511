/**
 * Created by BFR on 26/12/2019.
 */
define('orderUtils',[
  'module',
  'underscore',
  'app',
  'jquery',
  'historyPopupView',
  'contextMenu',
  'entities/orders',
  'entities/orders_kanban'
], function(
  module,
  _,
  App,
  $,
  HistoryPopupView
) {
  'use strict';

  var OrderUtils = {
      _initContextMenu: function(menu) {
          var that = this;
          menu.$el.contextMenu({
              selector: '.context-menu',
              items: {
                  poi: {
                      name: menu.model.get('poi'),
                      callback: _.bind(function() {
                          that.copyToClipboard(menu.model.get('poi'));
                      }, menu)
                  },
                  sep: '---------',
                  detail: {
                      name: _.i18n('common.details'),
                      callback: function() {
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  window.open(window.origin + '/#orders/' + $(item).data('id'));
                              });
                          } else {
                              window.open(window.origin + '/#orders/' + $(this).data('id'));
                          }
                      }
                  },
                  sep0: '---------',
                  cancel: {
                      name: _.i18n('common.cancel'),
                      callback: function() {
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order-kanban:update:table', $(item).data('id'), 'CANCELLED')).done(function () {
                                      $(item).remove();
                                  });
                              });
                          } else {
                              var item = $(this);
                              var secId = item.attr('data-id');
                              $.when(App.request('order-kanban:update:table', secId, 'CANCELLED')).done(function () {
                                  item.remove();
                              });
                          }
                      }
                  },
                  sep1: '---------',
                  declined: {
                      name: _.i18n('common.declined'),
                      callback: function() {
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order-kanban:update:table', $(item).data('id'), 'DECLINED')).done(function () {
                                      $(item).remove();
                                  });
                              });
                          } else {
                              var item = $(this);
                              var secId = item.attr('data-id');
                              $.when(App.request('order-kanban:update:table', secId, 'DECLINED')).done(function () {
                                  item.remove();
                              });
                          }
                      }
                  },
                  sep2: '---------',
                  duplicate: {
                      name: _.i18n('common.duplicate'),
                      callback: function() {
                          var secId = $(this).attr('data-id');
                          var model = App.request('order:model', { purchaseOrderId: secId });
                          model.duplicate().done(_.bind(function(newOffer){
                              App.navigate('orders/' + newOffer.value, {trigger: true});
                          }, this));
                      }
                  },
                  sep3: '---------',
                  history: {
                      name: _.i18n('common.history'),
                      callback: function() {
                          var secId = $(this).attr('data-id');
                          that._showHistoryPopup(secId);
                      }
                  },
                  sep4: '---------',
                  payedByCustomer: {
                      name: _.i18n('offerList.payedByCustomer'),
                      callback: function() {
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order:update', {
                                      purchaseOrderId: $(item).data('id'),
                                      payedByCustomer: true
                                  }));
                              });
                          } else {
                              var poi = $(this).attr('data-id');
                              $.when(App.request('order:update', {
                                  purchaseOrderId: poi,
                                  payedByCustomer: true
                              }));
                          }
                      }
                  },
                  sep5: '---------',
                  flag: {
                      name: _.i18n('offerList.flag'),
                      callback: function() {
                          var flag = 'E';
                          if (menu.model.get('flag') === flag) {
                              flag = null;
                          }
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order:update', {
                                      purchaseOrderId: $(item).data('id'),
                                      flag: flag
                                  }));
                              });
                          } else {
                              var poi = $(this).attr('data-id');
                              $.when(App.request('order:update', {
                                  purchaseOrderId: poi,
                                  flag: flag
                              }));
                          }
                      }
                  },
                  sep6: '---------',
                  prio1: {
                      name: _.i18n('offerList.prio1'),
                      callback: function() {
                          var prio = 1;
                          if (menu.model.get('priority') === prio) {
                              prio = null;
                          }
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order:update', {
                                      purchaseOrderId: $(item).data('id'),
                                      priority: prio
                                  }));
                              });
                          } else {
                              var poi = $(this).attr('data-id');
                              $.when(App.request('order:update', {
                                  purchaseOrderId: poi,
                                  priority: prio
                              }));
                          }
                      }
                  },
                  sep7: '---------',
                  prio2: {
                      name: _.i18n('offerList.prio2'),
                      callback: function() {
                          var prio = 2;
                          if (menu.model.get('priority') === prio) {
                              prio = null;
                          }
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order:update', {
                                      purchaseOrderId: $(item).data('id'),
                                      priority: prio
                                  }));
                              });
                          } else {
                              var poi = $(this).attr('data-id');
                              $.when(App.request('order:update', {
                                  purchaseOrderId: poi,
                                  priority: prio
                              }));
                          }
                      }
                  },
                  sep8: '---------',
                  toInvoice: {
                      name: _.i18n('offerList.toInvoice'),
                      callback: function() {
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order:update', {
                                      purchaseOrderId: $(item).data('id'),
                                      toInvoice: true
                                  }));
                              });
                          } else {
                              var poi = $(this).attr('data-id');
                              $.when(App.request('order:update', {
                                  purchaseOrderId: poi,
                                  toInvoice: true
                              }));
                          }
                      }
                  }
              },
              trigger: 'right'
          });
      },

      _showHistoryPopup: function(purchaseOrderId) {
          var popupView = new HistoryPopupView({
              url: App.request('order-kanban:history-get', purchaseOrderId),
              rowCountUrl: App.request('order-kanban:history-row-count', purchaseOrderId)
          });

          popupView.show({
              title: _.i18n('common.history'),
              className: 'popupHistory'
          });
      },

      copyToClipboard: function(str) {
          var el = document.createElement('textarea');
          el.value = str;
          el.setAttribute('readonly', '');
          el.style.position = 'absolute';
          el.style.left = '-9999px';
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
      }
  };

  module.exports = OrderUtils;
});

