
define('template!ordersKanbanFiltersDesign2Tpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-jqgrid orders-kanban-filters">\n  <div class="filters-blinder hide"></div>\n  <div class="ui-jqgrid-hbox p-r-0">\n    <table class="ui-jqgrid-htable ui-common-table">\n      <thead>\n        <tr class="ui-jqgrid-labels" role="row">\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default p-l-0">\n            <span></span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default p-l-0">\n            <span>' +
((__t = ( _.i18n('orderList.orderNumber') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default p-l-0">\n            <span>' +
((__t = ( _.i18n('orderList.configurationNumber') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default p-l-0">\n            <span>' +
((__t = ( _.i18n('orderList.endUser') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default p-l-0">\n            <span>' +
((__t = ( _.i18n('orderList.orderCreatDate') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default p-l-0">\n            <span>' +
((__t = ( _.i18n('orderList.productCode') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default p-l-0">\n            <span>' +
((__t = ( _.i18n('orderList.productName') )) == null ? '' : __t) +
'</span>\n          </th>\n        </tr>  \n        <tr class="ui-search-toolbar" role="row">\n          <th role="columnheader" class="ui-state-default ui-th-ltr">\n            <div class="p-l-10 m-t-5 quick-filters-mark">QUICK FILTERS:</div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_externalNumber">\n            <div>\n                <table class="ui-search-table" cellspacing="0">\n                    <tbody>\n                    <tr>\n                        <td class="ui-search-input">\n                            <input type="text" name="externalNumber" id="gs_externalNumber" role="textbox" class="ui-widget-content ui-corner-all"\n                                ';
 if (filters && filters.externalNumber){;
__p += ' value="' +
((__t = ( filters.externalNumber )) == null ? '' : __t) +
'" ';
};
__p += '>\n                            <a name="externalNumber" title="Reset Search Value" class="clearsearchclass">\n                                x\n                            </a>\n                        </td>\n                    </tr>\n                    </tbody>\n                </table>\n            </div>\n         </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_purchaseOrderId">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>                    \n                      <td class="ui-search-input">\n                        <input type="text" name="purchaseOrderId" id="gs_purchaseOrderId" role="textbox" class="ui-widget-content ui-corner-all"\n                        ';
 if (filters && filters.purchaseOrderId){;
__p += ' value="' +
((__t = ( filters.purchaseOrderId )) == null ? '' : __t) +
'" ';
};
__p += '>\n                        <a name="purchaseOrderId" title="Reset Search Value" class="clearsearchclass">\n                          x\n                        </a>\n                      </td>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_endUserId">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>                    \n                      <td class="ui-search-input">\n                        <input type="text" name="endUserId" id="gs_endUserId" role="textbox" class="ui-widget-content ui-corner-all"\n                        ';
 if (filters && filters.endUserId){;
__p += ' value="' +
((__t = ( filters.endUserId )) == null ? '' : __t) +
'" ';
};
__p += '>\n                        <a name="endUserId" title="Reset Search Value" class="clearsearchclass">\n                          x\n                        </a>\n                      </td>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_createDate">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>                    \n                      <td class="ui-search-input">\n                        <input type="period-picker-popover" name="createDate" id="gs_createDate" role="textbox" class="ui-widget-content ui-corner-all js-date-field"\n                        ';
 if (filters && filters.createDate){;
__p += ' value="' +
((__t = ( filters.createDate )) == null ? '' : __t) +
'" ';
};
__p += '>\n                        <a name="createDate" title="Reset Search Value" class="clearsearchclass">\n                          x\n                        </a>\n                      </td>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_productCode">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>                    \n                      <td class="ui-search-input">\n                        <input type="text" name="productCode" id="gs_productCode" role="textbox" class="ui-widget-content ui-corner-all"\n                        ';
 if (filters && filters.productCode){;
__p += ' value="' +
((__t = ( filters.productCode )) == null ? '' : __t) +
'" ';
};
__p += '>\n                        <a name="productCode" title="Reset Search Value" class="clearsearchclass">\n                          x\n                        </a>\n                      </td>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_productName">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>                    \n                      <td class="ui-search-input">\n                        <input type="text" name="productName" id="gs_productName" role="textbox" class="ui-widget-content ui-corner-all"\n                        ';
 if (filters && filters.productName){;
__p += ' value="' +
((__t = ( filters.productName )) == null ? '' : __t) +
'" ';
};
__p += '>\n                        <a name="productName" title="Reset Search Value" class="clearsearchclass">\n                          x\n                        </a>\n                      </td>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n        </tr>\n      </thead>\n    </table>\n  </div>\n</div>';

}
return __p
};});

