
define('template!headerShareRequestViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<span class="pull-left">\n<a class="currentShareRequest">\n<img src="' +
((__t = ( sharedObject.cAccountLogoUrl )) == null ? '' : __t) +
'" style="max-width: 50px; max-height: 30px;" />\n</a><span>' +
((__t = ( sharedObject.name )) == null ? '' : __t) +
'</span>\n</span>\n<span class="pull-right remove-accept-icons">\n    <i class="fa fa-remove fa-fw remove-request"></i>\n    <i class="fa fa-check fa-fw accept-request"></i>\n</span>';

}
return __p
};});

